import {useEffect, useMemo} from 'react';
import TMapSender, {
  ECallbackKeys,
  EMapStyle,
  TMapReceiver,
  getMapFontSizeNameFromNumber,
  getParsedTmapInfo,
  useMapStyle,
} from '@lcc/tmap-inapp';
import * as TMapInApp from 'utils/tmapInApp';
import actions from 'ducks/actions';
import {fetchUserData} from 'ducks/userInfo/slice';
import {EAddressType, TMapContext} from 'ducks/userInfo/types';
import {
  WEB_ACCESS_KEY,
  WEB_AD_ID,
  WEB_DEFAULT_CARRIER,
  WEB_DEVICE_ID,
  WEB_SESSION_ID,
  WEB_USER_KEY,
} from 'constant/Env';
import {
  CENTER_WGS84,
  DEFAULT_WGS84_LAT_NUM,
  DEFAULT_WGS84_LON_NUM,
  SEARCH_DEFAULT_ZOOM,
} from 'constant/Map';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import ua from 'utils/uaParser';
import {getParam} from 'utils/apis';
import {TLonLat} from 'types/Map';
import {convertAppToWebZoomLevel} from 'utils/map';
import {useOnce} from 'hooks/useOnce';
import useInitApiHeader from 'hooks/useInitApiHeader';
import {useSearchPageVisible} from 'hooks/useSearchPageVisible';
import useUserPublicTransData from 'hooks/useUserPublicTransData';

const SUPPORT_MAP_STYLE = [EMapStyle.NIGHT, EMapStyle.DAY];

const useUserData = ({ignorePosition}: {ignorePosition?: boolean} = {}) => {
  const dispatch = useAppDispatch();
  const {resumeKey, accessKey, euk, refreshKey, isUserDataLoaded} = useAppSelector((state) => ({
    refreshKey: state.userInteraction.refreshKey,
    resumeKey: state.userInteraction.resumeKey,
    accessKey: state.userInfo.accessKey,
    euk: state.userInfo.euk,
    isUserDataLoaded: state.userInfo.personalPlace.loaded,
  }));

  const {showMain} = useSearchPageVisible();
  const {init: fetchUserPublicTransData} = useUserPublicTransData();

  const mapStyleInfo = useMemo(() => {
    const tmapInfo = getParsedTmapInfo();

    return tmapInfo
      ? {
          [EMapStyle.SATELLITE]: tmapInfo.isSatelliteModeMap,
          [EMapStyle.NIGHT]: tmapInfo.isNightModeMap,
        }
      : undefined;
  }, []);

  const mapStyle = useMapStyle(SUPPORT_MAP_STYLE, mapStyleInfo);

  const isHeaderInitialized = useInitApiHeader();

  useOnce(!resumeKey && !accessKey && !ua.isInApp, () => {
    const testAk = getParam('tak') || WEB_ACCESS_KEY;
    const testUserKey = getParam('tuk') || WEB_USER_KEY;
    const testFavorite = getParam('tfav');
    const testRecentlyDestination = getParam('trdes');

    dispatch(
      actions.userInfo.setUserInfos({
        accessKey: testAk,
        sessionKey: `${testAk}_${Date.now()}`,
        sessionId: WEB_SESSION_ID,
        adId: WEB_AD_ID,
        userKey: testUserKey,
        mapContext: {
          x: DEFAULT_WGS84_LON_NUM,
          y: DEFAULT_WGS84_LAT_NUM,
          tilt: 0,
          rotate: 0,
          zoom: SEARCH_DEFAULT_ZOOM,
        },
        showFavorite: testFavorite === 'true' ? true : false,
        showRecentlyDestination: testRecentlyDestination === 'true' ? true : false,
        addressType: EAddressType.ROAD,
        device: {
          carrierName: WEB_DEFAULT_CARRIER,
          deviceId: WEB_DEVICE_ID,
        },
      })
    );
  });

  useOnce(ua.isInApp && !euk, () => {
    TMapSender.getEUK();

    TMapReceiver.setCallback(ECallbackKeys.USER_KEY, (encodedUserKey: string) => {
      dispatch(actions.userInfo.setEuk(encodedUserKey));
    });
  });

  useOnce(!resumeKey && !accessKey && ua.isInApp, () => {
    // 공용
    TMapSender.getServerType();
    TMapSender.getUserKey();
    TMapSender.getDeviceServiceVendorId();
    TMapSender.getUserCarFuel();
    TMapSender.getUserCarModel();
    TMapSender.getUserSetting('feature.sendDestinationToCar' as any);

    // tmapInfo
    const tmapInfo = TMapSender.getTmapInfo();

    // 9.9 부터는 tmapInfo를 사용
    if (!!tmapInfo) {
      dispatch(
        actions.userInfo.setUserInfos({
          accessKey: tmapInfo.ak,
          sessionKey: `${tmapInfo.ak}_${Date.now()}`,
          sessionId: tmapInfo.sessionId,
          adId: tmapInfo.adid,
          showFavorite: tmapInfo.isShowFavorite,
          showRecentlyDestination: tmapInfo.isShowRecentlyDestination,
          addressType:
            parseInt(`${tmapInfo.addressType || 0}`) === 1 ? EAddressType.ROAD : EAddressType.JIBUN,
          device: {
            carrierName: tmapInfo.carrier,
            deviceId: tmapInfo.deviceId,
          },
          carInfo: {
            model: tmapInfo.carModel,
            fuel: tmapInfo.carFuel,
          },
        })
      );

      dispatch(actions.map.setFontSize(getMapFontSizeNameFromNumber(tmapInfo.fontSize)));
      dispatch(actions.layout.setLandscape(tmapInfo.orientation % 2 === 0));
      dispatch(actions.layout.setStatusBarHeight(tmapInfo.statusBarHeight));
      if (tmapInfo.displayHeight > 0) {
        dispatch(actions.layout.setDisplayHeight(tmapInfo.displayHeight));
      }

      return;
    }

    // 9.9 미만 버전의 레거시 방식
    TMapSender.getSessionId();
    TMapSender.getAccessKey();
    TMapSender.getDeviceAdId();
    TMapSender.getDeviceId();
    TMapSender.getMapFontSize();
    TMapSender.isShowFavorite();
    TMapSender.isShowRecentlyDestination();
    TMapSender.isRoadAddressType();
    TMapSender.getCarrierName();
    TMapSender.getUserSetting('feature.sendDestinationToCar' as any);
  });

  useOnce(!ua.isInApp && !ignorePosition, () => {
    TMapInApp.getLastPosition()
      .then((lonLat) => {
        dispatch(actions.map.setUserPosition(lonLat));
        dispatch(actions.map.setNowCenter(lonLat));
      })
      .catch(() => {
        dispatch(actions.map.setUserPosition(CENTER_WGS84));
        dispatch(actions.map.setNowCenter(CENTER_WGS84));
      });
  });

  useOnce(ua.isInApp && !ignorePosition, () => {
    const updatePositionStatus = (centerPos: TLonLat = {lon: 0, lat: 0}) => {
      TMapInApp.getLastPosition()
        .then((lonLat) => {
          // const isValidCenter = centerPos.lon > 0 && centerPos.lat > 0;
          // const mapCenter = isValidCenter ? centerPos : lonLat;
          // const mapCenter = ignorePosition? lonLat : isValidCenter ? centerPos : lonLat;
          // ignorePosition이 true일 경우, 지도의 마지막 위치 무시하고, 현재 위치로 이동
          // ignorePosition이 false일 경우, 지도의 마지막 위치로 이동 (default)
          // ignorePosition이 바뀌는 시점과 지도가 적용되는 시점이 다름. -> PlaceCategoryPage에서 적용안됨.
          // 이전에 SearchMainPage에서 ignorePosition이 false (default)값 설정되어 있어서, 그때 값으로 적용되어 지도 노출.

          dispatch(actions.map.setUserPosition(lonLat));
          dispatch(actions.map.setNowCenter(lonLat));

          // AOS getCurrentMapContext 동작되지 않는 이슈가 있어서 추가.
          // 이렇게 할경우 검색결과 없을때 디폴트 위치로 이동됨.
          // 없으면 디폴트, 있으면 퀵서치 시에 주변이 마지막 위치
          dispatch(
            actions.userInfo.setMapContext({
              x: lonLat.lon,
              y: lonLat.lat,
            })
          );
        })
        .catch(() => {
          dispatch(actions.map.setUserPosition(CENTER_WGS84));
          dispatch(actions.map.setNowCenter(CENTER_WGS84));
        });
    };

    TMapInApp.getCurrentMapContext()
      .then((mapContext: TMapContext) => {
        // https://tmobi.atlassian.net/browse/CLIENTQA-4022
        // CLIENTQA-4022 상황(즐겨찾기 등록, 삭제 후 퀵서치 진입시) ios는 현재 위치를 가져옴, AOS는 지도 마지막 위치를 가져옴.
        dispatch(
          actions.userInfo.setMapContext({
            ...mapContext,
            zoom: convertAppToWebZoomLevel(mapContext.zoom),
          })
        );
        updatePositionStatus({lon: mapContext.x, lat: mapContext.y});
      })
      .catch(() => {
        updatePositionStatus();
      });
  });

  useEffect(() => {
    // resume 시에 새로 가져옴
    if (ua.isInApp && resumeKey) {
      TMapSender.getSessionId();
      TMapSender.getAccessKey();
      TMapSender.getDeviceAdId();
      TMapSender.getDeviceServiceVendorId();
      TMapSender.getDeviceId();
      TMapSender.getMapFontSize();
      TMapSender.isShowFavorite();
      TMapSender.isShowRecentlyDestination();
      TMapSender.isRoadAddressType();
      TMapSender.getCarrierName();
      TMapSender.getUserSetting('feature.sendDestinationToCar' as any);
    }

    if (isHeaderInitialized) {
      // wiki: https://tmobi.atlassian.net/wiki/spaces/TSSQUARE/pages/35848247/05.+User+RecentDestination
      dispatch(fetchUserData());
    }
  }, [dispatch, resumeKey, isHeaderInitialized]);

  useEffect(() => {
    if (!showMain && isUserDataLoaded) {
      fetchUserPublicTransData();
    }
  }, [isUserDataLoaded]);

  useEffect(() => {
    if (refreshKey) {
      window.location.reload();
    }
  }, [refreshKey]);

  useEffect(() => {
    dispatch(actions.map.setStyle(mapStyle));
  }, [dispatch, mapStyle]);
};

export default useUserData;
