import VSMMap from 'components/VSMMap';
import {useRef, useState, useCallback, useMemo, useEffect, HTMLAttributes} from 'react';
import {EMarkerType, TBoundsPadding, TLonLat, TPosition} from 'types/Map';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import actions from 'ducks/actions';
import {EListMode} from 'types/ListDrawer';
import {TNowItem} from 'ducks/tnow/types';
import {TPlaceItem} from 'ducks/place/types';
import {Point} from '@vsm/vsm';
import usePlaceBounds from 'hooks/usePlaceBounds';
import {usePlaceMapMarker} from 'hooks/usePlaceMapMarker';
import {convertToVSMPosition, getValidBounds, getValidLonLat, getOffsetCenter} from 'utils/map';
import {useVSMInterfaceConsumer} from 'context/VSMInterfaceContext';
import useLogger from 'hooks/useLogger';
import {EActionId, EMapActionId} from 'constant/Log';
import {filterCustomMarker} from 'utils/marker';
import VSMMarker from './VSMMarker';
import {useMapTextOverlay} from 'hooks/useMapTextOverlay';
import useMap from 'hooks/useMap';
import {MOVE_SPEED} from 'constant/Map';
import {useOnce} from 'hooks/useOnce';
import {useMapRotateCenter} from 'hooks/useMapRotateCenter';
import useMapMarkerConfig from 'hooks/useMapMarkerConfig';
import {TRankItem} from 'ducks/rank/types';
import PersonalMarkerLayer from './map/personalMarkerLayer/PersonalMarkerLayer';

type TProps = HTMLAttributes<HTMLDivElement> & {
  initZoom?: number;
  boundsPadding?: TBoundsPadding;
  list?: TPlaceItem[] | TNowItem[] | TRankItem[];
  markerClickToCenter?: boolean;
  defaultCenter?: TLonLat;
  centerOffset?: Point;
  boundOffset?: Point;
  useUserPositionBound?: boolean;
  useDataCenterPointBound?: boolean;
  initLoad?: boolean;
  priorityBounds?: Partial<TBoundsPadding>;
  initPitch?: number;
  initBearing?: number;
  personMarkerLayerVisible?: boolean;
};

const PlaceMap = ({
  initPitch,
  initBearing,
  initZoom,
  boundsPadding,
  list = [],
  markerClickToCenter,
  defaultCenter,
  centerOffset,
  boundOffset,
  useUserPositionBound,
  useDataCenterPointBound,
  initLoad,
  priorityBounds,
  personMarkerLayerVisible,
}: TProps) => {
  const dispatch = useAppDispatch();
  const {
    mapStyle,
    mapFontSize,
    focusPoiId,
    calloutInfo,
    lastCachedCenter,
    windowSize,
    refreshStart,
    dataLonLat,
    nowPitch,
    nowBearing,
  } = useAppSelector((state) => ({
    mapStyle: state.map.style,
    mapFontSize: state.map.fontSize,
    debugMode: state.map.debugMode,
    focusPoiId: state.userInteraction.activePoi,
    calloutInfo: state.userInteraction.calloutInfo,
    lastCachedCenter: state.map.lastCachedCenter,
    windowSize: state.layout.windowSize,
    refreshStart: state.userInteraction.refreshStart,
    dataLonLat: state.place.dataLonLat,
    isLandscape: state.layout.appSize.isLandscape,
    nowPitch: state.map.nowPitch,
    nowBearing: state.map.nowBearing,
  }));

  const {markerStyleConfig} = useMapMarkerConfig();

  const {
    markers: originMarkers,
    poiMarkers,
    currentPositionMarker,
    calloutInfoMarker,
    priorityIncludeMarker,
  } = usePlaceMapMarker(list, focusPoiId);

  const markers = useMemo(() => {
    if (!originMarkers) {
      return [];
    }

    const hasRank = originMarkers.some((m) => m?.rank);
    const sortedMarker = hasRank
      ? originMarkers.sort((a, b) => (b?.rank || 0) - (a?.rank || 0))
      : originMarkers;

    return sortedMarker;
  }, [originMarkers]);

  const boundAroundCenter = useMemo(() => {
    if (useDataCenterPointBound) {
      return {lonLat: dataLonLat || defaultCenter};
    }

    return currentPositionMarker;
  }, [currentPositionMarker, dataLonLat, defaultCenter, useDataCenterPointBound]);

  const [useCenterDistanceBounds, setUseCenterDistanceBounds] = useState(
    useDataCenterPointBound || useUserPositionBound
  );
  const {centerDistanceBounds, bounds} = usePlaceBounds(boundAroundCenter, poiMarkers);
  const {moveCoordIntoView} = useMap();

  const prevCachedCenter = useRef<Nullable<TLonLat>>(null);
  const vsm = useVSMInterfaceConsumer();
  const {sendClickLogWithMapView} = useLogger();
  const {updateOverlay, clearOverlay} = useMapTextOverlay();

  useMapRotateCenter(centerOffset);

  const initPosition = useRef(false);
  const prevZoom = useRef(0);
  const [bw, bs, be, bn] = useMemo(
    () => (useCenterDistanceBounds ? centerDistanceBounds : bounds) || [],
    [useCenterDistanceBounds, centerDistanceBounds, bounds]
  );
  const [position, setPosition] = useState<TPosition & {fixLevel?: number}>({
    center: defaultCenter,
    bounds: useCenterDistanceBounds ? centerDistanceBounds : bounds,
    isCenterBounds: useCenterDistanceBounds,
    fixLevel: undefined,
  });
  const refAfterMoveEndQue = useRef<Array<() => void>>([]);
  const vsmPosProps = useMemo(() => {
    return {
      isCenterBounds: position.isCenterBounds,
      zoomLevel: position.fixLevel,
      center: position.center,
      bounds: position.bounds,
      boundsPadding,
      priorityIncludeCoord: priorityIncludeMarker?.lonLat,
      centerOffset,
      boundOffset,
    };
  }, [
    boundOffset,
    boundsPadding,
    centerOffset,
    position.bounds,
    position.center,
    position.fixLevel,
    position.isCenterBounds,
    priorityIncludeMarker?.lonLat,
  ]);

  useEffect(() => {
    if (priorityIncludeMarker?.description) {
      updateOverlay({
        label: priorityIncludeMarker.description,
        lat: priorityIncludeMarker.lonLat.lat,
        lon: priorityIncludeMarker.lonLat.lon,
      });
    } else {
      clearOverlay();
    }
  }, [
    priorityIncludeMarker?.lonLat.lon,
    priorityIncludeMarker?.lonLat.lat,
    priorityIncludeMarker?.description,
    updateOverlay,
    clearOverlay,
  ]);

  useOnce(initLoad && vsm.camera, () => {
    if (poiMarkers.length === 0) {
      setPosition({
        fixLevel: initZoom ? initZoom : undefined,
        center: defaultCenter,
        bounds: undefined,
        isCenterBounds: false,
      });

      if (list.length > 0) {
        vsm.camera?.flyTo?.(
          {center: convertToVSMPosition(defaultCenter), zoom: initZoom},
          {offset: centerOffset, animate: false}
        );
      }
    }
  });

  useEffect(() => {
    if (initPosition.current && poiMarkers.length === 1) {
      const fixCenter = poiMarkers[0]?.lonLat;

      setPosition((prev) => {
        if (prev.isCenterBounds) {
          return prev;
        }

        return {
          fixLevel: 17,
          center: fixCenter,
          bounds: undefined,
          isCenterBounds: false,
        };
      });
    }
  }, [poiMarkers, poiMarkers.length]);

  useEffect(() => {
    const validBounds = getValidBounds([bw, bs, be, bn]);

    if (initPosition.current && validBounds) {
      setPosition((prev) => {
        if (prev.fixLevel) {
          return prev;
        }
        const isCalloutInfoMarker = calloutInfoMarker;
        return {
          fixLevel: undefined,
          center: undefined,
          bounds: isCalloutInfoMarker ? undefined : validBounds,
          isCenterBounds: false,
        };
      });
    }
  }, [bs, bw, bn, be, calloutInfoMarker]);

  useEffect(() => {
    const validCenter = getValidLonLat(lastCachedCenter);

    if (initPosition.current && validCenter && lastCachedCenter?.from === 'app') {
      setPosition({bounds: undefined, fixLevel: undefined, center: validCenter});
    }
  }, [lastCachedCenter]);

  useEffect(() => {
    if (!initPosition.current && centerDistanceBounds) {
      if (boundOffset) {
        setPosition({
          fixLevel: undefined,
          center: undefined,
          bounds: useCenterDistanceBounds ? centerDistanceBounds : bounds,
          isCenterBounds: useCenterDistanceBounds,
        });
        setUseCenterDistanceBounds(useUserPositionBound ? false : true);
      } else {
        setPosition({
          center: undefined,
          fixLevel: undefined,
          bounds: bounds,
          isCenterBounds: false,
        });
      }

      initPosition.current = true;
    }
  }, [centerDistanceBounds, bounds, boundOffset, useUserPositionBound, useCenterDistanceBounds]);

  const handleClickMarker = useCallback(
    (e) => {
      const p = e.properties;
      const activePoi = p.listId;

      dispatch(actions.userInteraction.clearCalloutInfo());
      dispatch(
        actions.userInteraction.setInteraction({
          activePoi,
          trigger: 'marker',
          drawerMode: markerClickToCenter ? EListMode.CENTER : undefined,
          dragMap: false,
        })
      );
      sendClickLogWithMapView(EMapActionId.TAP_DOT_MARKER, {
        pkey: p.pkey,
        marker_type: filterCustomMarker(e.properties, false, {markerConfig: markerStyleConfig})
          ?.markerType,
        rank_num: p.rank,
      });
      setPosition({center: undefined, bounds: undefined, fixLevel: undefined});
    },
    [dispatch, markerClickToCenter, markerStyleConfig, sendClickLogWithMapView]
  );

  const handleDragEndMap = useCallback(() => {
    setPosition({center: undefined, bounds: undefined, fixLevel: undefined});
    sendClickLogWithMapView(EMapActionId.PANNING_MAP);
  }, [sendClickLogWithMapView]);

  const handleMoveEndMap = useCallback(
    (e) => {
      const offsetCenter = getOffsetCenter({offset: vsmPosProps.centerOffset, map: vsm.map});

      if (e.data.domEvent && !refreshStart) {
        dispatch(actions.userInteraction.setDragMap(true));
      }

      const newLastCachedCenter =
        lastCachedCenter?.from === 'app' ? lastCachedCenter : offsetCenter.offsetLonLat;

      !prevCachedCenter.current &&
        newLastCachedCenter &&
        dispatch(actions.map.setLastCachedCenter({...newLastCachedCenter, from: 'map'}));
      refreshStart && dispatch(actions.userInteraction.setRefreshStart(false));

      setPosition({center: undefined, bounds: undefined, fixLevel: undefined});

      initPosition.current = true;
      prevCachedCenter.current = null;

      dispatch(actions.map.setZoom(vsm.camera?.getZoom() || 0));

      const que = [...refAfterMoveEndQue.current];

      refAfterMoveEndQue.current = [];

      if (!e.data.byRotate) {
        while (que.length > 0) {
          const func = que.shift();

          func?.();
        }
      }
    },
    [vsmPosProps.centerOffset, vsm.map, vsm.camera, refreshStart, lastCachedCenter, dispatch]
  );

  const handleLongPressMap = useCallback(
    (e) => {
      dispatch(
        actions.userInteraction.setCalloutInfo({
          lon: e.point.lon,
          lat: e.point.lat,
          markerType: EMarkerType.ACTIVE,
          poiId: '',
          pkey: '',
          stationSktId: '',
          publicTransportType: undefined,
        })
      );

      sendClickLogWithMapView(EMapActionId.LONG_TAP_MAP);
    },
    [dispatch, sendClickLogWithMapView]
  );

  const handleClickPoi = useCallback(
    (feature, a) => {
      if (feature) {
        const [lon, lat] = feature.geometry.coordinates;
        const {properties} = feature;
        const markerType = properties.type as EMarkerType;

        switch (markerType as EMarkerType) {
          case EMarkerType.SAVE_CLUSTER:
            const isSingleCount = properties.count && properties.count === 1;
            const currentZoom = vsm.camera?.getZoom() || 0;
            let targetZoom = currentZoom > 9 || isSingleCount ? 13 : 10;
            vsm.camera?.flyTo({center: [lon, lat], zoom: targetZoom});
            break;
          case EMarkerType.SAVE_POI:
            dispatch(
              actions.userInteraction.setCalloutInfo({
                ...properties,
                markerType: properties.type,
              })
            );
            break;
          case EMarkerType.FAVORITE_HOME:
          case EMarkerType.FAVORITE_OFFICE:
          case EMarkerType.FAVORITE_PUBLIC_TRANS:
          case EMarkerType.RECENT_DESTINATION:
            dispatch(
              actions.userInteraction.setCalloutInfo({
                lon: properties.wgs84NavX,
                lat: properties.wgs84NavY,
                centerX: properties.centerX,
                centerY: properties.centerY,
                customName: properties.customName || '',
                navSeq: properties.navSeq,
                navX: properties.navX,
                navY: properties.navY,
                pkey: properties.pkey,
                poiId: properties.poiId,
                stationSktId: properties.stationSktId || properties.stationId,
                rpFlag: properties.rpFlag,
                personalPoiKey: properties.personalPoiKey,
                favId: properties.favId,
                publicTransportType: properties.publicTransportType,
                publicTransportName: properties.publicTransportName,
                markerType,
              })
            );
            sendClickLogWithMapView(
              markerType === EMarkerType.FAVORITE
                ? EMapActionId.TAP_FAVORITE_MARKER
                : EMapActionId.TAP_RECENT_DESTINATION_MARKER
            );
            break;
          default:
            const publicTransportType = properties?.publicTransportType;
            dispatch(
              actions.userInteraction.setCalloutInfo({
                lon,
                lat,
                poiId: properties.id,
                pkey: properties.pkey,
                stationSktId: properties.stationSktId,
                customName: properties?.customName || '',
                publicTransportType,
                publicTransportName: publicTransportType ? properties.name1 : '',
                markerType: EMarkerType.ACTIVE,
              })
            );
            sendClickLogWithMapView(EMapActionId.TAP_MAP_POI);
        }
      }
    },
    [dispatch, sendClickLogWithMapView, vsm.camera]
  );

  const handleClickMap = useCallback(
    (e) => {
      if (calloutInfo) {
        dispatch(actions.userInteraction.clearCalloutInfo());
        // dispatch(actions.userInteraction.setInteraction({drawerMode: EListMode.CENTER}));
        sendClickLogWithMapView(EActionId.DIMMED);
        return;
      }

      sendClickLogWithMapView(EMapActionId.TAP_MAP);
    },
    [calloutInfo, dispatch, sendClickLogWithMapView]
  );

  const handleZoomStartMap = useCallback(
    (e) => {
      if (!e.data.domEvent) {
        return;
      }

      const zoom = vsm.camera?.getZoom();

      if (zoom) {
        prevZoom.current = zoom;
      }
    },
    [vsm.camera]
  );

  const handleZoomEndMap = useCallback(
    (e) => {
      if (!e?.data?.domEvent) {
        return;
      }
      const zoom = vsm.camera?.getZoom();

      dispatch(actions.map.setZoom(zoom || 0));

      if (zoom && prevZoom.current) {
        const isZoomIn = prevZoom.current < zoom;

        sendClickLogWithMapView(isZoomIn ? EMapActionId.PINCH_OUT_MAP : EMapActionId.PINCH_IN_MAP);
      }
    },
    [vsm.camera, sendClickLogWithMapView, dispatch]
  );

  const handleConfigLoad = useCallback(() => {
    dispatch(actions.map.setZoom(vsm.camera?.getZoom() || 0));
  }, [vsm, dispatch]);

  useEffect(() => {
    const vsmCenter = convertToVSMPosition(vsmPosProps.center);
    const dest = {
      center: vsmCenter,
      ...(vsmPosProps.zoomLevel ? {zoom: vsmPosProps.zoomLevel} : {}),
    };

    const offset = {
      x: vsmPosProps.centerOffset?.x ?? undefined,
      y: vsmPosProps.centerOffset?.y ?? undefined,
    };

    vsmCenter &&
      offset.x !== undefined &&
      offset.y !== undefined &&
      vsm.camera?.flyTo(dest, {
        offset: {
          x: offset.x,
          y: offset.y,
        },
        speed: MOVE_SPEED,
      });
  }, [
    vsmPosProps.center?.lat,
    vsmPosProps.center?.lon,
    vsmPosProps.centerOffset?.x,
    vsmPosProps.centerOffset?.y,
    vsmPosProps.zoomLevel,
    vsmPosProps.center,
    vsm.camera,
  ]);

  useEffect(() => {
    vsmPosProps.bounds &&
      !vsmPosProps.priorityIncludeCoord &&
      vsm.camera?.setBounds(vsmPosProps.bounds, {
        padding: vsmPosProps.boundsPadding,
        offset: vsmPosProps.isCenterBounds ? vsmPosProps.boundOffset : undefined,
        speed: MOVE_SPEED,
        pitch: nowPitch,
        bearing: nowBearing,
      });
  }, [
    vsmPosProps.bounds,
    vsmPosProps.boundsPadding,
    vsmPosProps.isCenterBounds,
    vsmPosProps.boundOffset,
    nowPitch,
    nowBearing,
    vsm.camera,
    vsmPosProps.priorityIncludeCoord,
  ]);

  useEffect(() => {
    if (vsmPosProps.priorityIncludeCoord?.lat && vsmPosProps.priorityIncludeCoord?.lon) {
      const rePosition = () => {
        const top = (vsmPosProps.boundsPadding?.top || 0) + (priorityBounds?.top || 0);
        const left = (vsmPosProps.boundsPadding?.left || 0) + (priorityBounds?.left || 0);
        const right = (vsmPosProps.boundsPadding?.right || 0) + (priorityBounds?.right || 0);
        const bottom = (vsmPosProps.boundsPadding?.bottom || 0) + (priorityBounds?.bottom || 0);

        moveCoordIntoView(vsmPosProps.priorityIncludeCoord, {top, left, right, bottom});
      };

      if (vsm.camera?.isMoving()) {
        refAfterMoveEndQue.current.push(rePosition);
      } else {
        setTimeout(() => {
          rePosition();
        }, 300);
      }
    }
  }, [
    vsmPosProps.boundsPadding?.top,
    vsmPosProps.boundsPadding?.bottom,
    vsmPosProps.boundsPadding?.left,
    vsmPosProps.boundsPadding?.right,
    vsmPosProps.priorityIncludeCoord?.lat,
    vsmPosProps.priorityIncludeCoord?.lon,
    priorityBounds?.top,
    priorityBounds?.left,
    priorityBounds?.right,
    priorityBounds?.bottom,
    vsm.camera,
    moveCoordIntoView,
    vsmPosProps.priorityIncludeCoord,
  ]);

  return (
    <>
      <VSMMap
        initCameraOptions={{
          bearing: initBearing,
          pitch: initPitch,
        }}
        size={windowSize}
        mapStyle={mapStyle}
        fontSize={mapFontSize}
        initOptions={{bounds, padding: boundsPadding}}
        onClick={handleClickMap}
        onClickPoi={handleClickPoi}
        onDragEndMap={handleDragEndMap}
        onMoveEnd={handleMoveEndMap}
        onLongPressMap={handleLongPressMap}
        onZoomStart={handleZoomStartMap}
        onZoomEnd={handleZoomEndMap}
        onConfigLoad={handleConfigLoad}
      />
      {markers?.map(
        (m, i) =>
          m && (
            <VSMMarker
              key={i}
              lonLat={m.lonLat}
              type={m.type}
              anchor={m.anchor}
              label={m.description}
              data-type={m.type}
              clickable={m.clickable}
              onClick={(e) => {
                if (m.clickable) {
                  e.stopPropagation();
                  handleClickMarker?.(m);
                }
              }}
            >
              {m.children}
            </VSMMarker>
          )
      )}
      {calloutInfoMarker && (
        <VSMMarker {...calloutInfoMarker} key={JSON.stringify(calloutInfoMarker.lonLat)} />
      )}

      {personMarkerLayerVisible && (
        <PersonalMarkerLayer ignorePoiIdList={markers?.map((item) => item.properties?.poiId)} />
      )}
    </>
  );
};

export default PlaceMap;
