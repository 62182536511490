import {useCallback, useEffect, useRef} from 'react';
import actions from 'ducks/actions';
import {EMarkerType, TBoundsPadding, TCenterOffset, TLonLat} from 'types/Map';
import {EListMode} from 'types/ListDrawer';
import {MOVE_SPEED, SEARCH_DEFAULT_ZOOM, SEARCH_MAP_LIMIT_OPTION} from 'constant/Map';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {ESearchCollectionType} from 'ducks/search/types';
import useMap from 'hooks/useMap';
import useCurrentPosition from 'hooks/useCurrentPosition';
import useSearchResultMarker from 'hooks/useSearchResultMarker';
import {useOnce} from 'hooks/useOnce';
import useLogger from 'hooks/useLogger';
import {useMapTextOverlay} from 'hooks/useMapTextOverlay';
import {useSearchPageVisible} from 'hooks/useSearchPageVisible';
import useMapMarkerConfig from 'hooks/useMapMarkerConfig';
import {convertToLonLat, getValidLonLat} from 'utils/map';
import {filterCustomMarker} from 'utils/marker';
import VSMMap from 'components/VSMMap';
import VSMMarker from 'components/VSMMarker';

import s from 'styles/components/search/SearchResultMap.module.scss';
import {useVSMInterfaceConsumer} from 'context/VSMInterfaceContext';
import {EMapActionId} from 'constant/Log';
import PersonalMarkerLayer from 'components/map/personalMarkerLayer/PersonalMarkerLayer';

type TProps = {
  boundsPadding: TBoundsPadding;
  centerOffset: TCenterOffset;
  mapOffset: TBoundsPadding;
  centerToCurrentPosition?: boolean;
};

const SearchResultMap = ({
  boundsPadding,
  centerOffset,
  mapOffset,
  centerToCurrentPosition = false,
}: TProps) => {
  const dispatch = useAppDispatch();
  const {showResult} = useSearchPageVisible();

  const {
    isInitialized,
    getCenter,
    moveToCenter,
    getMapViewPort,
    getFixedZoomLevel,
    moveCoordIntoView,
    resize,
  } = useMap();
  const {currentPosition} = useCurrentPosition();
  const {updateOverlay, clearOverlay} = useMapTextOverlay();
  const {sendClickLogWithMapView} = useLogger();
  // const vsm = useVSMInterfaceConsumer();

  const {
    mapStyle,
    mapFontSize,
    nowCenter,
    rdSearch,
    calloutInfo,
    mapContext,
    userInteraction,
    isLandscape,
    windowSize,
    lastCachedCenter,
    userPosition,
  } = useAppSelector((state) => ({
    mapStyle: state.map.style,
    mapFontSize: state.map.fontSize,
    nowCenter: state.map.nowCenter,
    rdSearch: state.search,
    calloutInfo: state.userInteraction.calloutInfo,
    mapContext: state.userInfo.mapContext,
    userInteraction: state.userInteraction,
    isLandscape: state.layout.appSize.isLandscape,
    windowSize: state.layout.windowSize,
    lastCachedCenter: state.map.lastCachedCenter,
    userPosition: state.map.userPosition,
  }));

  const {markerStyleConfig} = useMapMarkerConfig();

  const {calloutInfoMarker, searchResultMarkers, activeMarker} = useSearchResultMarker();
  const refIsOrientationChanging = useRef<boolean>(false);
  const refCenterOffset = useRef<{x: number; y: number}>();
  const refCenter = useRef<TLonLat>();
  const vsm = useVSMInterfaceConsumer();

  const resetTriggerInteraction = useCallback(() => {
    dispatch(
      actions.userInteraction.setInteraction({
        trigger: 'none',
      })
    );
  }, [dispatch]);

  const setMapViewPort = useCallback(() => {
    const mapViewPort = getMapViewPort(mapOffset);

    if (mapViewPort) {
      resetTriggerInteraction();
      dispatch(actions.map.setViewPort(mapViewPort));
    }
  }, [dispatch, getMapViewPort, mapOffset, resetTriggerInteraction]);

  const setMapCenter = useCallback(
    (item) => {
      if (!item) {
        return;
      }

      moveToCenter(item.lonLat, {duration: 300, offset: centerOffset});
      updateOverlay({
        label: item.properties.description || item.properties.listName,
        lat: item.lonLat.lat,
        lon: item.lonLat.lon,
      });
      dispatch(actions.userInteraction.setDragMap(false));
      resetTriggerInteraction();
    },
    [moveToCenter, updateOverlay, dispatch, centerOffset, resetTriggerInteraction]
  );

  const handleMoveEnd = useCallback(
    (e?) => {
      if (!showResult) {
        return;
      }
      const center = convertToLonLat(getCenter());

      if (center?.lat && center?.lon) {
        dispatch(actions.map.setNowCenter(center));

        if (e?.data.domEvent && !rdSearch.loading && rdSearch.loaded) {
          dispatch(actions.userInteraction.setDragMap(true));
          dispatch(actions.map.setLastCachedCenter({...center, from: 'map'}));
        }
      }

      setMapViewPort();
      refCenter.current = center;
      dispatch(actions.map.setZoom(getFixedZoomLevel() || 0));
    },
    [
      getCenter,
      dispatch,
      rdSearch.loading,
      rdSearch.loaded,
      setMapViewPort,
      getFixedZoomLevel,
      showResult,
    ]
  );

  const handleClickMap = useCallback(() => {
    if (calloutInfo) {
      dispatch(actions.userInteraction.clearCalloutInfo());
    }

    sendClickLogWithMapView('tap.map');
  }, [dispatch, calloutInfo, sendClickLogWithMapView]);

  const handleClickPoi = useCallback(
    (poiFeature) => {
      const {properties, geometry} = poiFeature;
      const [lon, lat] = geometry.coordinates;
      const markerType = properties.type as EMarkerType;

      switch (markerType as EMarkerType) {
        case EMarkerType.SAVE_CLUSTER:
          const isSingleCount = properties.count && properties.count === 1;
          const currentZoom = vsm.camera?.getZoom() || 0;
          let targetZoom = currentZoom > 9 || isSingleCount ? 13 : 10;
          vsm.camera?.flyTo({center: [lon, lat], zoom: targetZoom});
          break;
        case EMarkerType.SAVE_POI:
          dispatch(
            actions.userInteraction.setCalloutInfo({
              ...properties,
              markerType: properties.type,
            })
          );
          break;
        case EMarkerType.FAVORITE_HOME:
        case EMarkerType.FAVORITE_OFFICE:
        case EMarkerType.FAVORITE_PUBLIC_TRANS:
        case EMarkerType.RECENT_DESTINATION:
          dispatch(
            actions.userInteraction.setCalloutInfo({
              lon: properties.wgs84NavX,
              lat: properties.wgs84NavY,
              centerX: properties.centerX,
              centerY: properties.centerY,
              customName: properties.customName || '',
              navSeq: properties.navSeq,
              navX: properties.navX,
              navY: properties.navY,
              pkey: properties.pkey,
              poiId: properties.poiId,
              stationSktId: properties.stationSktId || properties.stationId,
              rpFlag: properties.rpFlag,
              personalPoiKey: properties.personalPoiKey,
              favId: properties.favId,
              publicTransportType: properties.publicTransportType,
              publicTransportName: properties.publicTransportName,
              markerType,
            })
          );
          sendClickLogWithMapView(
            markerType === EMarkerType.FAVORITE
              ? EMapActionId.TAP_FAVORITE_MARKER
              : EMapActionId.TAP_RECENT_DESTINATION_MARKER
          );
          break;
        default:
          const publicTransportType = properties?.publicTransportType;
          dispatch(
            actions.userInteraction.setCalloutInfo({
              lon,
              lat,
              poiId: properties.id,
              pkey: properties.pkey,
              stationSktId: properties.stationSktId,
              customName: properties?.customName || '',
              publicTransportType,
              publicTransportName: publicTransportType ? properties.name1 : '',
              markerType: EMarkerType.ACTIVE,
            })
          );
          sendClickLogWithMapView(EMapActionId.TAP_MAP_POI);
      }
      moveCoordIntoView({lon, lat}, boundsPadding);
      clearOverlay();
    },
    [moveCoordIntoView, boundsPadding, clearOverlay, vsm.camera, dispatch, sendClickLogWithMapView]
  );

  const handleLongPressMap = useCallback(
    (e) => {
      const {lon, lat} = e.point;

      moveCoordIntoView({lon, lat}, boundsPadding);
      dispatch(
        actions.userInteraction.setCalloutInfo({
          lon,
          lat,
          markerType: EMarkerType.ACTIVE,
          poiId: '',
          pkey: '',
          stationSktId: '',
          publicTransportType: undefined,
        })
      );
      clearOverlay();
      sendClickLogWithMapView('longtap.map');
    },
    [dispatch, moveCoordIntoView, boundsPadding, sendClickLogWithMapView]
  );

  const handleClickInactiveMarker = useCallback(
    (e, m) => {
      e.preventDefault();
      e.stopPropagation();

      const p = m.properties;

      if (calloutInfo) {
        // 초기화 위치 변경 => https://tde.sktelecom.com/pms/browse/LEGORENEW-464 수정반영
        dispatch(actions.userInteraction.clearCalloutInfo());
      }
      setMapCenter(m);
      dispatch(
        actions.userInteraction.setInteraction({
          activePoi: p.listId,
          drawerMode: EListMode.CENTER,
          trigger: 'marker',
        })
      );

      sendClickLogWithMapView('tap.map_dot', {
        pkey: m.properties.pkey,
        marker_type: filterCustomMarker(m.properties, false, {markerConfig: markerStyleConfig})
          ?.markerType,
      });
    },
    [calloutInfo, setMapCenter, dispatch, sendClickLogWithMapView, markerStyleConfig]
  );

  useOnce(!!activeMarker, () => {
    if (activeMarker?.lonLat) {
      moveToCenter(
        {
          ...activeMarker.lonLat,
          zoom: SEARCH_DEFAULT_ZOOM,
        },
        {animate: false, offset: centerOffset}
      );
    }
  });

  useEffect(() => {
    if (!nowCenter) {
      // 초기 중심점 로딩
      handleMoveEnd();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized]);

  useEffect(() => {
    if (centerToCurrentPosition && currentPosition) {
      window.setTimeout(() => {
        moveToCenter(
          {
            lon: currentPosition.lon,
            lat: currentPosition.lat,
            zoom: SEARCH_DEFAULT_ZOOM,
          },
          {animate: false, offset: centerOffset}
        );
      }, 0);
    }
  }, [centerToCurrentPosition, currentPosition]);

  useEffect(() => {
    window.setTimeout(() => {
      setMapViewPort();
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLandscape, windowSize]);

  useEffect(() => {
    if (rdSearch.loading || rdSearch.loaded) {
      dispatch(actions.userInteraction.setDragMap(false));
    }
  }, [dispatch, rdSearch.loading, rdSearch.loaded]);

  useEffect(() => {
    if (rdSearch.data.collectionType === ESearchCollectionType.BUS_LINE) {
      const pos = userPosition;

      if (pos) {
        moveToCenter(pos, {speed: 3});
      }
    }
  }, [rdSearch.data.collectionType, rdSearch.loaded]);

  useEffect(() => {
    if (refIsOrientationChanging.current) {
      if (userInteraction.trigger === 'scroll') {
        refIsOrientationChanging.current = false;
      }

      return;
    }

    if (userInteraction.trigger === 'scroll') {
      setMapCenter(activeMarker);
    }
  }, [userInteraction]);

  useEffect(() => {
    activeMarker?.lonLat && dispatch(actions.map.setNowMarkerPosition(activeMarker.lonLat));
  }, [dispatch, activeMarker?.lonLat]);

  useEffect(() => {
    const center = refCenter.current;

    if (center && centerOffset && refCenterOffset.current) {
      moveToCenter(center, {
        animate: false,
        offset: {
          y: 0,
          x: isLandscape ? centerOffset.x : refCenterOffset.current.x * -1,
        },
      });
      refCenterOffset.current = undefined;
    }

    return () => {
      refIsOrientationChanging.current = true;
      refCenterOffset.current = centerOffset;
    };
  }, [isLandscape]);

  useEffect(() => {
    window.setTimeout(() => {
      resize();
    }, 500);
  }, [isLandscape]);

  useEffect(() => {
    const validCenter = getValidLonLat(lastCachedCenter);

    if (validCenter && lastCachedCenter?.from === 'app') {
      moveToCenter(validCenter, {speed: MOVE_SPEED, offset: centerOffset});
    }
  }, [lastCachedCenter]);

  return (
    <div className={s.map_wrap} role="presentation" aria-hidden="true">
      <VSMMap
        initOptions={
          mapContext && mapContext.x && mapContext.y
            ? {
                center: {
                  lng: mapContext.x,
                  lat: mapContext.y,
                },
                zoom: mapContext.zoom || SEARCH_DEFAULT_ZOOM,
                bearing: mapContext.rotate || 0,
                pitch: mapContext.tilt || 0,
                ...SEARCH_MAP_LIMIT_OPTION,
              }
            : {}
        }
        fontSize={mapFontSize}
        mapStyle={mapStyle}
        onClickPoi={handleClickPoi}
        onClick={handleClickMap}
        onMoveEnd={handleMoveEnd}
        onLongPressMap={handleLongPressMap}
        onDragStart={() => sendClickLogWithMapView('panning.map')}
        onZoomEnd={(e) => {
          e.data?.domEvent && sendClickLogWithMapView(e.isZoomIn ? 'pinchout.map' : 'pinchin.map');
        }}
      />

      {currentPosition && (
        <VSMMarker
          lonLat={{lon: currentPosition.lon, lat: currentPosition.lat}}
          type={EMarkerType.CURRENT_POSITION}
          clickable={false}
        />
      )}

      {searchResultMarkers.map((m, i) => (
        <VSMMarker
          key={i}
          {...m}
          uniqueId={m.properties?.listId}
          onClick={(e) => handleClickInactiveMarker(e, m)}
        />
      ))}

      {calloutInfoMarker && (
        <VSMMarker {...calloutInfoMarker} key={JSON.stringify(calloutInfoMarker.lonLat)} />
      )}

      <PersonalMarkerLayer
        ignorePoiIdList={searchResultMarkers.map((item) => item.properties.poiId)}
      />
    </div>
  );
};

export default SearchResultMap;
