import Vsm from '@vsm/vsm';
import {useVSMInterfaceConsumer} from 'context/VSMInterfaceContext';
import {useOnce} from 'hooks/useOnce';
import {useCallback, useEffect, useMemo, useRef} from 'react';
import {EMarkerType, TMarker} from 'types/Map';
import {useAppSelector} from 'ducks/hooks';
import {getImageResources, groupMarkerImageMap} from './utils';
import {EMapStyle} from '@lcc/tmap-inapp';

const useLayerManager = () => {
  const storeState = useAppSelector((state) => state);
  const {map} = useVSMInterfaceConsumer();
  const packageCode = useRef('OVERLAY_0');
  const layerId = useRef(10020);
  const stackId = useRef(20020);
  const stackItemId = useRef(30020);
  const markerItems = useRef<TMarker[]>([]);
  const currentFeatures = useRef<any[]>([]);
  const activeFeature = useRef();
  const activePersonalPoiKey = useMemo(
    () => storeState.userInteraction.calloutInfo?.personalPoiKey,
    [storeState.userInteraction.calloutInfo?.personalPoiKey]
  );
  const clusterCountMarkers = useRef<Vsm.Marker[]>([]);

  // 사용할 이미지 등록
  const addImageResource = useCallback(async () => {
    const newPackageCode = `OVERLAY_${packageCode.current}`;
    const images = getImageResources();

    await map?.addImageResources(newPackageCode, images, {pixelRatio: 3});

    await map?.addStyle(layerId.current, {
      [stackId.current]: images.map((item) => {
        return {
          id: stackItemId.current++,
          condition: `$markerType{stackItem-${item.id}} and $visibility{show}`,
          icon: {
            icon: `${newPackageCode}:${item.id}`, // 패키지 이름 + 이미지 이름과 대칭
            'icon-optional': true,
            'icon-allow-overlap': true,
          },
          label: {
            display: 'off',
          },
        };
      }),
    });
  }, [map]);

  // todo : 밤낮 적용해야함
  const getImageIdByMarkerItem = useCallback(
    (markerItem: TMarker<any>) => {
      let result = 'group01_icon_day';
      const dayNightCode = storeState.map.style === EMapStyle.NIGHT ? 'night' : 'day';
      const type = markerItem.type;
      switch (type) {
        case EMarkerType.SAVE_POI:
          const color = markerItem.properties.color || '';
          const id = groupMarkerImageMap[color.toUpperCase()];
          result = id ? `${id}_icon_${dayNightCode}` : result;
          break;
        case EMarkerType.SAVE_CLUSTER:
          result = `cluster_icon_${dayNightCode}`;
          break;
        case EMarkerType.FAVORITE_HOME:
          result = `home_icon_${dayNightCode}`;
          break;
        case EMarkerType.FAVORITE_OFFICE:
          result = `office_icon_${dayNightCode}`;
          break;
        case EMarkerType.FAVORITE_PUBLIC_TRANS:
          result = `trans_icon_${dayNightCode}`;
          break;
        case EMarkerType.RECENT_DESTINATION:
          result = `recent_icon_${dayNightCode}`;
          break;
      }
      return result;
    },
    [storeState.map.style]
  );

  const updateClusterCountMarker = useCallback(() => {
    // reset
    if (clusterCountMarkers.current.length) {
      clusterCountMarkers.current.forEach((item) => item.destroy());
    }
    // render
    markerItems.current.forEach((markerItem: any) => {
      if (markerItem.type === EMarkerType.SAVE_CLUSTER) {
        const element = document.createElement('span');
        const count = markerItem.properties?.count || 0;
        const countText = count > 999 ? '999+' : `${count}`;
        element.setAttribute(
          'style',
          'position:absolute;background:white;border:1.5px solid #09429F;border-radius:16px;color:#0064FF;font-size:12px;line-height:12px;padding:3px 5px;font-weight:bold;top:-16px;pointer-events:none;'
        );
        element.innerText = countText;
        const marker = new Vsm.Marker({
          element,
          lngLat: {
            lng: markerItem.lonLat.lon,
            lat: markerItem.lonLat.lat,
          },
          map,
        });
        clusterCountMarkers.current.push(marker);
      }
    });
  }, [map]);

  const toggleSelectedMarker = useCallback(
    (targetPersonalPoiKey: string) => {
      // 비활성
      if (activeFeature.current) {
        // @ts-ignore
        activeFeature.current.properties.visibility = 'show';
      }
      if (targetPersonalPoiKey) {
        const targetFeature = currentFeatures.current.find(
          (feature) => feature.properties.personalPoiKey === targetPersonalPoiKey
        );
        if (targetFeature) {
          // @ts-ignore
          targetFeature.properties.visibility = 'hidden';
          activeFeature.current = targetFeature;
        }
      }
      map?.updateGeoJsonSourceData(layerId.current, {
        type: 'FeatureCollection',
        features: currentFeatures.current,
      });
    },
    [map]
  );

  const getFeatures = useCallback(() => {
    return markerItems.current.map((markerItem) => {
      // 이미지 아이디를 찾아야함.
      const imageId = getImageIdByMarkerItem(markerItem);
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [markerItem.lonLat.lon, markerItem.lonLat.lat],
        },
        properties: {
          ...markerItem.properties,
          // @ts-ignore
          id: `${markerItem.type}_${markerItem.properties.personalPoiKey}`,
          grade: '-1',
          isCustomPoint: 'true', // 클릭 우선순위를 판단하기 위함. useMap.ts 에서 선택. only string
          // @ts-ignore
          name1: `${markerItem.properties.personalPoiKey}`,
          // @ts-ignore
          type: markerItem.type,
          stackId: stackId.current,
          markerType: `stackItem-${imageId}`,
          originalPoint: JSON.stringify([markerItem.lonLat.lon, markerItem.lonLat.lat]),
          visibility:
            // @ts-ignore
            activePersonalPoiKey === markerItem.properties.personalPoiKey ? 'hidden' : 'show',
        },
      } as any;
    });
  }, [activePersonalPoiKey, getImageIdByMarkerItem]);

  const render = useCallback(async () => {
    const features = getFeatures();

    if (map?.hasLayer(layerId.current)) {
      await map?.updateGeoJsonSourceData(layerId.current, {
        type: 'FeatureCollection',
        features,
      });
    } else {
      map?.addLayer(
        {
          id: layerId.current,
          name: '레이어1',
          map: {
            format: 'geojson',
            preventOverlap: false,
            data: {
              type: 'FeatureCollection',
              features,
            },
          },
          stacks: [
            {
              id: stackId.current,
              code: 'OVERLAY_0', // 이건뭔지 모르겠음.
              name: '오버레이마커',
              geometryType: 'POINT',
              properties: {
                sortBy: 'grade',
                poi: '1',
              },
            },
          ],
        },
        10
      );
    }
    updateClusterCountMarker();
    currentFeatures.current = features;
  }, [getFeatures, map, updateClusterCountMarker]);

  const updateMarkers = useCallback(
    (_markerItems: TMarker[]) => {
      markerItems.current = _markerItems;
      render();
    },
    [render]
  );

  useOnce(!!map, () => {
    map?.on(Vsm.Map.EventNames.StyleLoad, () => addImageResource());
  });

  useEffect(() => {
    activePersonalPoiKey && toggleSelectedMarker(activePersonalPoiKey);
  }, [activePersonalPoiKey, toggleSelectedMarker]);

  return {
    updateMarkers,
  };
};

export default useLayerManager;
