import {useVSMInterfaceConsumer} from 'context/VSMInterfaceContext';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {EMarkerType, TMarker} from 'types/Map';
import {frontmanFetcher} from 'utils/fetcher';
import {convertWgs84ToSk, getExpendedBounds} from 'utils/map';
import TMapSender, {ECallbackKeys, TMapReceiver} from '@lcc/tmap-inapp';
import actions from 'ducks/actions';
import {TGroupItem, TSavePoiReqParams, TSaveRes} from './types';
import {useOnce} from 'hooks/useOnce';
import Vsm from '@vsm/vsm';
import {EPersonalMarkerEvent, personalMarkerEmitter} from './PersonalMarkerEmitter';

const MAP_EXPEND_VALUE = 150;
const DEFAULT_COLOR = '#FFBD05';

const useSaveMarker = () => {
  const storeState = useAppSelector((state) => state);
  const {saveMarkerDisplay, lastCachedCenter, nowCenter} = storeState.map;
  const dispatch = useAppDispatch();
  const {map} = useVSMInterfaceConsumer();

  const initLoaded = useRef(false);
  const [saveData, setSaveData] = useState<Nullable<TSaveRes>>();
  const [groupList, setGroupList] = useState<TGroupItem[]>([]);

  const resultData = useMemo(() => {
    if (!saveMarkerDisplay) {
      return null;
    }
    return saveData;
  }, [saveData, saveMarkerDisplay]);

  const groupColorData = useMemo(() => {
    return groupList.reduce((result, item) => {
      if (!result[item.groupId]) {
        result[item.groupId] = item.color;
      }
      return result;
    }, {} as {[key in string]: string});
  }, [groupList]);

  const getGroupColor = useCallback(
    (groupId: string) => groupColorData[groupId] || DEFAULT_COLOR,
    [groupColorData]
  );

  const savePoiMarkers = useMemo<TMarker[]>(() => {
    if (!resultData?.poiFavorites) {
      return [];
    }

    return resultData?.poiFavorites.map((v) => {
      const customName = v.custName || '';
      return {
        // callout info 데이터 형식에 맞춤
        properties: {
          lon: v.centerLon,
          lat: v.centerLat,
          centerX: v.centerX,
          centerY: v.centerY,
          customName: customName,
          navSeq: v.navSeq,
          navX: v.noorX,
          navY: v.noorY,
          pkey: v.pkey,
          poiId: v.poiId,
          personalPoiKey: v.favId, // 개인화마커 체크
          favId: v.favId, // 즐찾표시
          color: getGroupColor(v.groupId),
        },
        type: EMarkerType.SAVE_POI,
        lonLat: {
          lon: Number(v.centerLon),
          lat: Number(v.centerLat),
        },
      };
    });
  }, [getGroupColor, resultData?.poiFavorites]);

  const saveClusterMarkers = useMemo<TMarker[]>(() => {
    if (!resultData?.poiClusters) {
      return [];
    }
    return resultData.poiClusters.map((v) => ({
      properties: {
        ...v,
        personalPoiKey: v.geoHash,
        // type: EMarkerType.SAVE_CLUSTER,
      },
      // clickable: true,
      type: EMarkerType.SAVE_CLUSTER,
      lonLat: {
        lon: Number(v.centerLon),
        lat: Number(v.centerLat),
      },
    }));
  }, [resultData]);

  const updateSaveData = useCallback(async () => {
    if (!map) {
      return;
    }
    const {northEast, southWest} = getExpendedBounds(map, MAP_EXPEND_VALUE);
    const convertedNE = convertWgs84ToSk({
      latitude: northEast.lat,
      longitude: northEast.lng,
    });
    const convertedSW = convertWgs84ToSk({
      latitude: southWest.lat,
      longitude: southWest.lng,
    });
    const zoom = map?.getCamera().getZoom() || 0;
    const params: TSavePoiReqParams = {
      mapDispYn: 'Y',
      // 서버 기본값 13이하인 경우 클러스터링되지만, 클러스터 클릭시 줌인이 13레벨로 되기 때문에 13미만으로 임의 조정
      cluster: zoom < 13 ? 'true' : 'false',
      maxCount: 1000,
      mapLevel: Math.floor(zoom),
      minNoorX: convertedSW.x,
      minNoorY: convertedSW.y,
      maxNoorX: convertedNE.x,
      maxNoorY: convertedNE.y,
    };

    const res = await frontmanFetcher.get('/biz/flerken/api/v2/poi-favorite/$userKey/favorites', {
      params,
    });
    setSaveData({
      isClustered: res.data.isClustered,
      mapLevel: res.data.mapLevel,
      poiClusters: res.data.poiClusters,
      poiFavorites: res.data.poiFavorites,
      precision: res.data.precision,
    });
  }, [map]);

  const updateGroupData = useCallback(async () => {
    const res = await frontmanFetcher.get('/flerken/api/v2/poi-favorite/$userKey/groups');
    setGroupList(res.data.poiGroups);
  }, []);

  const updateDisplaySetting = useCallback(() => {
    TMapReceiver.setCallback(ECallbackKeys.MAP_INFO_FAVORITE, (result) => {
      dispatch(actions.map.setSaveMarkerDisplay(result));
    });
    TMapSender.getMapInfoFavorite();
  }, [dispatch]);

  const reloadAllData = useCallback(async () => {
    updateDisplaySetting();
    await updateGroupData();
    await updateSaveData();
  }, [updateDisplaySetting, updateGroupData, updateSaveData]);

  // 중심점 변경되면 데이터 새로 로드
  // 줌이 많이 변경되었거나. 0.3, 13기준 많이 이동되었거나 를 체크해야함.
  const oldCenter = useRef<Vsm.LngLat>();
  const oldZoom = useRef(0);
  useEffect(() => {
    if (!map || !saveMarkerDisplay || !initLoaded.current) {
      return;
    }
    const camera = map.getCamera();
    const currentZoom = camera.getZoom();
    const currentCenter = camera.getCenter();
    // zoom 체크
    const zoomCheck = () => {
      if (!oldZoom.current) {
        return true;
      }
      return Math.abs(currentZoom - oldZoom.current) > 0.3;
    };
    const moveCheck = () => {
      if (!oldCenter.current) {
        return true;
      }
      const transform = map.getTransform();
      const currentPoint = transform.getCenterPoint();
      const oldPoint = transform.lngLatToScreenPoint(oldCenter.current);
      const xValue = currentPoint.x - oldPoint.x;
      const yValue = currentPoint.y - oldPoint.y;
      return Math.abs(xValue) > MAP_EXPEND_VALUE || Math.abs(yValue) > MAP_EXPEND_VALUE;
    };

    if (zoomCheck() || moveCheck()) {
      updateSaveData();
      oldZoom.current = currentZoom;
      oldCenter.current = currentCenter;
    }
  }, [saveMarkerDisplay, updateSaveData, lastCachedCenter, nowCenter, map]);

  // resume시 데이터 갱신 (다른곳에서 그룹 편집됐을 수 있음.)
  useEffect(() => {
    if (initLoaded.current) {
      reloadAllData();
    }
  }, [reloadAllData, storeState.userInteraction.resumeKey]);

  // 그룹편집 팝업 열린 후 갱신 (팝업은 resume이 일어나지 않음)
  useEffect(() => {
    personalMarkerEmitter.on(EPersonalMarkerEvent.UPDATE_FAVORITE_STATE, reloadAllData);
    return () => {
      personalMarkerEmitter.off(EPersonalMarkerEvent.UPDATE_FAVORITE_STATE, reloadAllData);
    };
  }, [reloadAllData]);

  // 진입시 로드
  useOnce(!!map, async () => {
    await reloadAllData();
    initLoaded.current = true;
  });

  return {
    savePoiMarkers,
    saveClusterMarkers,
  };
};

export default useSaveMarker;
