import useSaveMarker from './useSaveMarker';
import useUserInfoMarker from './useUserInfoMarker';
import {useEffect, useMemo} from 'react';
import compact from 'lodash/compact';
import useLayerManager from './useLayerManager';

type TProps = {
  ignorePoiIdList?: (string | number | undefined)[];
};

const PersonalMarkerLayer = (props: TProps) => {
  const markerLayer = useLayerManager();
  const userInfoMarker = useUserInfoMarker();
  const saveMarker = useSaveMarker();

  const markers = useMemo(() => {
    let result = compact([
      ...saveMarker.savePoiMarkers,
      ...saveMarker.saveClusterMarkers,
      userInfoMarker.favoriteHomeMarker,
      userInfoMarker.favoriteOfficeMarker,
      ...userInfoMarker.favoritePublicMarker,
      ...userInfoMarker.recentDestinationMarker,
    ]);
    if (props.ignorePoiIdList?.length) {
      const dataType = typeof props.ignorePoiIdList[0];
      result = result.filter((item) => {
        const poiId =
          dataType === 'number' ? Number(item.properties.poiId) : String(item.properties.poiId);
        const ignored = props.ignorePoiIdList?.includes(poiId);
        return !ignored;
      });
    }
    return result;
  }, [
    props.ignorePoiIdList,
    saveMarker.saveClusterMarkers,
    saveMarker.savePoiMarkers,
    userInfoMarker.favoriteHomeMarker,
    userInfoMarker.favoriteOfficeMarker,
    userInfoMarker.favoritePublicMarker,
    userInfoMarker.recentDestinationMarker,
  ]);

  useEffect(() => {
    markerLayer.updateMarkers(markers);
  }, [markerLayer, markers]);

  return null;
};

export default PersonalMarkerLayer;
