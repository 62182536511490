import {useCallback, useState} from 'react';
import actions from 'ducks/actions';
import useUserData from 'hooks/useUserData';
import {JsonViewer} from '@textea/json-viewer';

import AsumBanner from 'modules/AsumBanner';
import TMapInternalBanner from 'modules/TMapInternalBanner';
import MolocoBanner from 'modules/MolocoBanner';
import NaverBanner from 'modules/NaverBanner';

import ADStateViewer, {DEFAULT_STATE, EBannerState, TBannerState} from 'modules/ADStateViewer';

import s from 'styles/pages/ProtoBannerPage.module.scss';
import {
  EAdType,
  ETMapBannerCode,
  PLACE_BANNER_AD_STEP,
  SEARCH_BANNER_AD_STEP,
  SEARCH_MAIN_BANNER_PROVIDER_CONFIG,
  SEARCH_RESULT_BANNER_PROVIDER_CONFIG,
  TEST_BANNER_PROVIDER_CONFIG,
} from 'constant/Ads';
import {APP_SERVER_NAME, WEB_DEFAULT_CARRIER} from 'constant/Env';
import {TAdOption} from 'components/AdBanner';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {useOnce} from 'hooks/useOnce';

const ProtoBannerPage = () => {
  const {rdUserInfo} = useAppSelector((state) => ({
    rdLayout: state.layout,
    rdUserInfo: state.userInfo,
  }));
  const dispatch = useAppDispatch();

  useUserData();

  const [size] = useState(window.innerWidth);

  const [adRunner, setAdRunner] = useState<
    {
      title: string;
      type: EAdType;
      state: TBannerState;
      config: TAdOption;
    }[]
  >([
    {
      title: '몰로코 (검색메인)',
      type: EAdType.MOLOCO,
      config: SEARCH_MAIN_BANNER_PROVIDER_CONFIG,
      state: DEFAULT_STATE,
    },
    {
      title: '몰로코 (검색결과)',
      type: EAdType.MOLOCO,
      config: SEARCH_RESULT_BANNER_PROVIDER_CONFIG,
      state: DEFAULT_STATE,
    },
    {
      title: 'ASUM (검색메인)',
      type: EAdType.ASUM,
      config: SEARCH_MAIN_BANNER_PROVIDER_CONFIG,
      state: DEFAULT_STATE,
    },
    {
      title: 'ASUM (검색결과)',
      type: EAdType.ASUM,
      config: SEARCH_RESULT_BANNER_PROVIDER_CONFIG,
      state: DEFAULT_STATE,
    },
    {
      title: '네이버 (검색메인)',
      type: EAdType.NAVER,
      config: SEARCH_MAIN_BANNER_PROVIDER_CONFIG,
      state: DEFAULT_STATE,
    },
    {
      title: 'TMAP internal - 검색 메인 / 결과',
      type: EAdType.INTERNAL,
      config: SEARCH_MAIN_BANNER_PROVIDER_CONFIG,
      state: DEFAULT_STATE,
    },
    {
      title: '몰로코 - 테스트 (for IOS)',
      type: EAdType.MOLOCO,
      config: {
        inner: {inventoryCode: ETMapBannerCode.PLACE_MAIN},
        ...TEST_BANNER_PROVIDER_CONFIG,
      },
      state: DEFAULT_STATE,
    },
    {
      title: 'ASUM - 테스트',
      type: EAdType.ASUM,
      config: {
        inner: {inventoryCode: ETMapBannerCode.PLACE_MAIN},
        ...TEST_BANNER_PROVIDER_CONFIG,
      },
      state: DEFAULT_STATE,
    },
  ]);

  const handleAdEvent = useCallback((runnerIdx, newState) => {
    setAdRunner((prevRunner) => {
      let runnerList = [...prevRunner];

      runnerList[runnerIdx] = {
        ...runnerList[runnerIdx],
        state: {
          ...runnerList[runnerIdx].state,
          ...(newState || {}),
        },
      };

      return runnerList;
    });
  }, []);

  useOnce(rdUserInfo?.adId && !rdUserInfo?.device.carrierName, () => {
    dispatch(actions.userInfo.setCarrierName(WEB_DEFAULT_CARRIER));
  });

  return (
    <div className={s.page_wrap}>
      <div className={s.tool}>
        <h2>참고용</h2>
        <JsonViewer
          value={{
            url: window.location.href,
            width: size,
            APP_SERVER_NAME,
            searchAdStep: SEARCH_BANNER_AD_STEP.join(' > '),
            placeAdStep: PLACE_BANNER_AD_STEP.join(' > '),
          }}
        />
      </div>
      {rdUserInfo?.adId &&
        rdUserInfo?.device.carrierName &&
        adRunner.map((runner, idx) => {
          const eventProps = {
            onStart: (reqParams: any) =>
              handleAdEvent(idx, {state: EBannerState.LOADING, param: reqParams}),
            onShow: (data: any) => handleAdEvent(idx, {state: EBannerState.SUCCESS, data}),
            onError: (error?: any) =>
              handleAdEvent(idx, {state: EBannerState.ERROR, error: {message: error.message}}),
          };

          return (
            <div className={s.ad_box} key={runner.title}>
              <ADStateViewer title={runner.title} state={runner.state} />
              <div className={s.banner_box}>
                {runner.type === EAdType.MOLOCO && (
                  <MolocoBanner {...eventProps} {...runner.config.moloco} />
                )}
                {runner.type === EAdType.ASUM && (
                  <AsumBanner
                    isShow={true}
                    {...eventProps}
                    {...runner.config.asum}
                    onShow={() => handleAdEvent(idx, {state: EBannerState.SUCCESS, data: {}})}
                  />
                )}
                {runner.type === EAdType.INTERNAL && (
                  <TMapInternalBanner {...eventProps} {...runner.config.inner} />
                )}
                {runner.type === EAdType.NAVER && (
                  <NaverBanner
                    {...eventProps}
                    {...runner.config.naver}
                    onShow={() => handleAdEvent(idx, {state: EBannerState.SUCCESS, data: {}})}
                  />
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ProtoBannerPage;
