import TMapSender from '@lcc/tmap-inapp';
import classNames from 'classnames';

import {JsonViewer} from '@textea/json-viewer';

import s from 'styles/modules/ADStateViewer.module.scss';

export const EXPANDED_PATH = [''];

export enum EBannerState {
  WAIT = 'WAIT',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export type TBannerState = {
  state: EBannerState;
  param?: any;
  data?: any;
  error?: any;
};

export const DEFAULT_STATE: TBannerState = {
  state: EBannerState.WAIT,
};

export type TProps = {
  title: string;
  state: TBannerState;
  sdkLoaded?: boolean;
  isColumn?: boolean;
};

const ADStateViewer = ({title, state, sdkLoaded, isColumn}: TProps) => {
  return (
    <>
      <h3 className={s.title}>
        {title}: {state.state}{' '}
        <button
          onClick={() => {
            TMapSender.copyClipboard(title, JSON.stringify({title, ...state}));
            TMapSender.makeToast(title + ' 상태정보가 복사되었습니다');
          }}
        >
          상태복사버튼
        </button>
        <div>{sdkLoaded !== undefined ? `[SDK] ${sdkLoaded ? '로딩완료' : '로딩전'}` : ''}</div>
      </h3>
      <div
        className={classNames(s.info_box, {
          [s.column]: isColumn,
        })}
      >
        <div>
          RequestParam:
          <JsonViewer value={state.param} />
        </div>
        <div>
          ResponseData:
          <JsonViewer value={state.data} />
        </div>
        <div>
          Error:
          <JsonViewer value={state.error} />
        </div>
      </div>
    </>
  );
};

export default ADStateViewer;
