import {useCallback, useState} from 'react';
import {useAppSelector} from 'ducks/hooks';
import {compareFavorite} from 'utils/map';
import useAppScheme from './useAppScheme';
import {isOverNewFavoriteVersion} from 'utils/tmapUtils';
import {useOnce} from './useOnce';

type TFavoriteItemType = string | number;
type TCheckIsFavoriteParams = {
  stationId?: TFavoriteItemType;
  pkey?: TFavoriteItemType;
  navX?: TFavoriteItemType;
  navY?: TFavoriteItemType;
  favId?: TFavoriteItemType;
};

type TFavoriteGroup = {
  poiDataList: TPoiData[];
  poiGroupList: TPoiGroup[];
};

type TPoiData = {
  pkey: string;
  poiId: string;
  navSeq: string;
  poiName: string;
  navX: string;
  navY: string;
  centerX: string;
  centerY: string;
  address: string;
  tel: string;
  rpFlag: number;
  stationId?: string;
  publicTransportType?: 'subway' | 'busstop';
  favMemo?: string;
  favId?: string;
  groupId?: string;
};

type TPoiGroup = {
  color: string;
  groupId: string;
  groupType: string;
  insDatetime: string;
  mapDispYn: 'Y' | 'N';
  name: string;
  poiCount: number;
  poiRegDatetime: string;
  updDatetime: string;
};

export type TCommonItem = {
  poiId: string | number;
  pkey: string | number;
};

type TProps = {
  list?: Array<TCommonItem>;
};

const useFavorite = (props?: TProps) => {
  const {personalPlace} = useAppSelector((state) => ({
    personalPlace: state.userInfo.personalPlace,
  }));
  const [favoriteList, setFavoriteList] = useState<TPoiData[]>([]);
  const [favoriteGroup, setFavoriteGroup] = useState<TPoiGroup[]>([]);
  const inApp = useAppScheme();

  const checkIsFavorite = useCallback(
    (data: TCheckIsFavoriteParams) => {
      if (!personalPlace.loaded || !data) {
        return false;
      }

      if (!!data.favId) {
        return true;
      }

      const {favorites, home, office, favoritesPublic} = personalPlace.data;
      const favoriteItem = [...favorites, ...favoritesPublic, home, office]
        .filter((v) => !!v)
        .find((f) => (f ? compareFavorite(f, data) : false));

      // 이전 비교 로직. 주소쪽 동작 검증 확인 예정
      // return (
      //   v.poiId === poiJson.poiId ||
      //   v.pkey === addressData?.roadPkey ||
      //   v.pkey === addressData?.jibunPkey ||
      //   // v.customName === poiJson.poiName ||
      //   // v.customName === poiJson.address ||
      //   (v.centerX === addressData?.centerX && v.centerY === addressData?.centerY)
      // );

      return !!favoriteItem;
    },
    [personalPlace]
  );

  const checkIsFavoriteFromList = useCallback(
    (item) => {
      if (!favoriteList.length) {
        return false;
      }

      return favoriteList.some((v) => compareFavorite(v, item));
    },
    [favoriteList]
  );

  const getFavoriteGroup = useCallback(
    async (data: TCommonItem[]) => {
      const result = (await inApp.getFavoriteGroup(
        data.map((item) => item.poiId)
      )) as TFavoriteGroup;
      const poiDataList = result?.poiDataList || [];
      const poiGroupList = result?.poiGroupList || [];

      setFavoriteList(poiDataList);
      setFavoriteGroup(poiGroupList);
    },
    [inApp]
  );

  const getFavoriteGroupColor = useCallback(
    (item: TCommonItem) => {
      if (!item || !item?.pkey || !item?.poiId) {
        return null;
      }

      const matchingGroupIds = favoriteList
        .filter((v) => compareFavorite(v, item))
        .map((v) => v.groupId)
        .filter((id): id is string => Boolean(id));

      if (matchingGroupIds.length === 0) {
        return null;
      }

      const matchingGroups = favoriteGroup
        .filter((group) => matchingGroupIds.includes(group.groupId))
        .map((group) => ({
          ...group,
        }))
        .sort((a, b) => b.updDatetime.localeCompare(a.updDatetime));

      return matchingGroups[0]?.color ?? null;
    },
    [favoriteGroup, favoriteList]
  );

  useOnce(
    props?.list && props.list.length && isOverNewFavoriteVersion() && !favoriteList.length,
    () => {
      if (props?.list) {
        getFavoriteGroup(props.list);
      }
    }
  );

  return {
    checkIsFavorite,
    getFavoriteGroup,
    favoriteList,
    checkIsFavoriteFromList,
    getFavoriteGroupColor,
  };
};

export default useFavorite;
